import React from 'react';

import { SearchContextProvider } from '@backstage/plugin-search-react';
import {
  CustomHomepageGrid,
  HomePageStarredEntities,
  HomePageRecentlyVisited,
  HomePageTopVisited,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';

import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';

import { HomePageMyComponentsCard } from '@internal/plugin-home-page-entity-cards';
import {
  AnnouncementsCard,
  NewAnnouncementBanner,
} from '@procore-oss/backstage-plugin-announcements';
import { Grid } from '@material-ui/core';

const defaultConfig = [
  {
    width: 3,
    height: 5,
    x: 0,
    y: 0,
    component: <HomePageRecentlyVisited />,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    width: 3,
    height: 5,
    x: 3,
    y: 5,
    component: <HomePageTopVisited />,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    width: 6,
    height: 5,
    x: 7,
    y: 5,
    component: <AnnouncementsCard max={5} />,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    width: 6,
    height: 5,
    x: 0,
    y: 5,
    component: <HomePageStarredEntities />,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    width: 6,
    height: 5,
    x: 6,
    y: 5,
    component: <HomePageMyComponentsCard />,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    width: 6,
    height: 5,
    x: 0,
    y: 5,
    component: <HomePageYourOpenPullRequestsCard />,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
  {
    width: 6,
    height: 5,
    x: 6,
    y: 5,
    component: <HomePageRequestedReviewsCard />,
    moved: false,
    static: false,
    isDraggable: false,
    isResizable: false,
  },
];

export const HomePage = () => {
  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid item>
            <NewAnnouncementBanner />
          </Grid>

          <CustomHomepageGrid
            config={defaultConfig}
            cols={{ lg: 12, xl: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
          >
            <HomePageStarredEntities />
            <HomePageRecentlyVisited />
            <HomePageTopVisited />
            <HomePageRequestedReviewsCard />
            <HomePageYourOpenPullRequestsCard />
            <HomePageMyComponentsCard />
            <AnnouncementsCard max={5} />
          </CustomHomepageGrid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
