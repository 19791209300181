import {
  createBaseThemeOptions,
  pageTheme as defaultPageThemes,
  PageTheme,
  palettes,
  createUnifiedTheme,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';

const pageThemesFontColorOverride: Record<string, PageTheme> = {};
Object.keys(defaultPageThemes).map(key => {
  pageThemesFontColorOverride[key] = {
    ...defaultPageThemes[key],
    fontColor: '#172B4D',
  };
});

export const axelerantDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#FF5722',
        light: '#4C9AFF',
        dark: '#172B4D',
      },
    },
  }),
  fontFamily: 'Roboto, sans-serif',
  pageTheme: pageThemesFontColorOverride,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundImage: 'unset',
          backgroundColor: '#FF5722',
          '@media print': {
            display: 'none',
          },
        },
        title: {
          color: '#fff',
          fontWeight: 900,
        },
        subtitle: {
          color: '#fff',
        },
        type: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          color: theme.page.fontColor,
        }),
        value: ({ theme }) => ({
          color: alpha(theme.page.fontColor, 0.8),
        }),
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: 'inherit',
          textTransform: 'none',
          '@media print': {
            display: 'none',
          },
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {
          '&> :first-child': {
            borderBottom: '1px solid #D5D5D5',
          },
          '& th': {
            borderTop: 'none',
            textTransform: 'none !important',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 3,
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.primary.dark,
          margin: 4,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#FFFFFF',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
  },
});
