import React from 'react';
import { makeStyles } from '@material-ui/core';

import axlLogo from './axelerant-logo-light.svg';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 55,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return <img className={classes.svg} src={axlLogo} alt="Logo" />;
};

export default LogoFull;
